<template>
  <div class="container dark">
    <section class="hero is-fullheight dark">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Cameron Daniel
          </h1>
          <h2 class="subtitle">
            <a class="icon" href="https://www.linkedin.com/in/camdaniel/" target="_blank">
              <Icon name="brands/linkedin" scale="1.3" />
            </a>
            <a class="icon" href="https://github.com/ccakes" target="_blank">
              <Icon name="brands/github" scale="1.3" />
            </a>
            <a class="icon" href="https://twitter.com/definitivelycam" target="_blank">
              <Icon name="brands/twitter" scale="1.3" />
            </a>
            <a class="icon" href="https://www.instagram.com/definitively.cam/" target="_blank">
              <Icon name="brands/instagram" scale="1.3" />
            </a>
            <a class="icon" href="https://keybase.io/cakes" target="_blank">
              <Icon name="brands/keybase" scale="1.3" />
            </a>
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import 'vue-awesome/icons/brands/linkedin';
  import 'vue-awesome/icons/brands/github';
  import 'vue-awesome/icons/brands/twitter';
  import 'vue-awesome/icons/brands/instagram';
  import 'vue-awesome/icons/brands/keybase';
  import Icon from 'vue-awesome/components/Icon';

  require('bubbly-bg');

  export default {
    name: 'app',
    components: {
      Icon
    },
    mounted() {
      // eslint-disable-next-line
      bubbly({
        bubbles: 20,
        colorStart: '#111',
        colorStop: '#222'
      });
    }
  };
</script>

<style lang="stylus" scoped>
  .title
    font-family Oxygen Mono, sans-serif
  
  .icon
    margin-right .05rem

  .keybase
    svg
      width 18px
      height 18px

      .st0
        fill #ccc

      &:hover
        .st0
          fill #fff
          stroke #fff
</style>
